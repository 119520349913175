import React from "react";
import Hero from "./hero";
import Services from "./service";

export default function Indec(){
    return(
        <div>
            <Hero />
            <Services />
        </div>
    );
}
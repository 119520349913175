import React from "react";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import Index from "../components/contact";


 const Contact = () => {
    return(
        <>
        <Header />
        <Index />
        <Footer />
        </>
    );
}

export default Contact;
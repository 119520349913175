import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div className="about_land">
      <div className="text" data-aos="fade-left" data-aos-duration="1000">
        <p>About Us</p>
        <span>Get To Know Envio</span>
        <h1>
          Envio Integrated Services Limited is your trusted partner for
          top-notch cleaning solutions. Our dedicated team of professionals
          caters to a diverse array of cleaning needs, from residential spaces
          to commercial establishments. With unwavering commitment and
          expertise, we ensure every corner sparkles, setting the gold standard
          for cleanliness. Your satisfaction is our priority.
        </h1>
        <Link to="/about">
          <button>
            Read More <i class="fa-solid fa-arrow-right"></i>
          </button>
        </Link>
      </div>
      <div className="image" data-aos="fade-right" data-aos-duration="1000">
        <img
          src={process.env.PUBLIC_URL + "/images/about_land.jpg"}
          alt="Cleaning Equipment"
        />
      </div>
    </div>
  );
}

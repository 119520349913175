import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  function toggleNav() {
    setIsNavOpen(!isNavOpen);
  }

  return (
    <nav data-aos="zoom-in" data-aos-duration="1000">
      <div className="logo">
        <Link to="/" className="link">
          <img
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="Envio Logo"
          />
        </Link>
      </div>
      <div className="bars" onClick={toggleNav}>
        <i className={`fa ${isNavOpen ? "fa-times" : "fa-bars"}`}></i>
      </div>
      <ul className={isNavOpen ? "open" : "close"} onClick={toggleNav}>
        <li>
          <Link to="/" className="link home">
            Home
          </Link>
        </li>
        <li>
          <Link to="/about-us" className="link">
            About Us
          </Link>
        </li>
        <li>
          <Link to="/service" className="link">
            Service
          </Link>
        </li>
        <li>
          <Link to="/contact-us" className="link">
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
}

import React from "react";

export default function About() {
  return (
    <div className="about_about">
      <div className="text">
        <p data-aos="fade-up" data-aos-duration="1000">
          Envio Integrated Services Ltd stands as a testament to Nigerian
          enterprise, fully registered with the Corporate Affairs Commission
          (CAC). Our journey is rooted in the heart of Nigeria, where we've
          established ourselves as a prominent player in the fields of Cleaning,
          Waste Management, Facility Management, Environmental Beautification,
          Hospitality, and General Procurement.
          <br />
          <br />
          With a homegrown spirit and a focus on delivering excellence, we've
          become an essential service provider across Nigeria. Our operations
          span both public and private sectors, with a commitment to providing
          sustainable solutions that not only enhance the environment but also
          support businesses and create spaces that are cleaner and more
          aesthetically pleasing.
          <br />
          <br />
          We are driven by the pursuit of excellence, with a clear mission to
          meet the diverse needs of our clients. Our reputation as a reliable
          and trusted partner is a source of pride. At the heart of our
          operations is a dedication to ensuring that our clients are not just
          satisfied but delighted with our services.
          <br />
          <br />
          Envio Integrated Services Ltd believes in setting high standards in
          all aspects of our work. Professionalism and environmental
          responsibility are key pillars of our foundation.
        </p>
        <p data-aos="fade-up" data-aos-duration="1000">
          Our team is trained to deliver exceptional services while adhering to
          environmentally friendly practices, reducing our carbon footprint, and
          contributing to a cleaner, greener future. Our adaptability and
          versatility have made us a preferred choice for a diverse range of
          clients, including government agencies, businesses, and institutions.
          <br />
          <br />
          We are known for our responsiveness and the ability to tailor our
          services to meet the unique requirements of our clients. As a company
          that deeply values environmental responsibility, we take pride in the
          steps we take to minimize our impact on the environment. This core
          value resonates through every service we offer, from cleaning to waste
          management and facility maintenance.
          <br />
          <br />
          Our journey is one of constant innovation and growth. We keep a
          vigilant eye on emerging technologies and solutions that can elevate
          the services we provide. This dynamic approach helps us to stay at the
          forefront of our industry. We invite you to join us on our journey as
          we continue making a positive impact, not only in Nigeria but also
          beyond.
          <br />
          <br />
          Envio Integrated Services Ltd stands as a beacon of Nigerian
          enterprise, and we look forward to sharing our journey of growth and
          success with you. Experience the Envio difference and be part of our
          story.
        </p>
      </div>
      <div className="mission">
        <p data-aos="fade-up" data-aos-duration="1000">Our Mission & Values</p>
        <strong data-aos="zoom-up" data-aos-duration="1000">
          we're committed to excellence in everything we do. Our services cater
          to both the public and private sectors, and we specialize in offering
          sustainable solutions that not only enhance the environment but also
          support businesses and create cleaner, more aesthetically pleasing
          spaces.
        </strong>
      </div>
    </div>
  );
}

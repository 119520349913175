import React from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { Autoplay } from "swiper/modules";

export default function Service() {
  return (
    <div className="service_land" data-aos="fade-up" data-aos-duration="1000">
      <div className="image">
        <img src={process.env.PUBLIC_URL + "/images/service_land.jpg"} />
      </div>
      <div className="text">
        <div className="text-top">
          <h1>__Our</h1>
          <p>SEVICES</p>
        </div>

        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide className="gap">
            <h1>Residential Cleaning</h1>
            <p>
              Experience a spotless and refreshed living space with our
              Residential Cleaning services. Our dedicated team meticulously
              dusts, vacuums, and scrubs every corner, ensuring your home,
              apartment, or condo shines with cleanliness. We tackle kitchens,
              bathrooms, and more, so you can enjoy a fresh, inviting space.
            </p>
            <Link to="/service">
              <button>
                Read More <i class="fa-solid fa-arrow-right"></i>
              </button>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="gap">
            <h1>Commercial Cleaning</h1>
            <p>
              Our Commercial Cleaning services cater to businesses and
              commercial spaces, including offices, retail areas, and industrial
              sites. We ensure a pristine, organized environment, promoting a
              professional and welcoming atmosphere.
            </p>
            <Link to="/service">
              <button>
                Read More <i class="fa-solid fa-arrow-right"></i>
              </button>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="gap">
            <h1>Deep Cleaning</h1>
            <p>
              Experience the ultimate cleanliness with our Deep Cleaning
              service. We go the extra mile to tackle hard-to-reach areas,
              thoroughly clean appliances, and rejuvenate fixtures, leaving your
              space impeccably fresh and sanitized.
            </p>
            <Link to="/service">
              <button>
                Read More <i class="fa-solid fa-arrow-right"></i>
              </button>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="gap">
            <h1>Eco-Friendly Cleaning</h1>
            <p>
              At Envio, we prioritize sustainability with our Eco-Friendly
              Cleaning. Our team uses environmentally conscious cleaning agents
              and methods that are gentle on the planet and safe for your
              well-being. Enjoy a clean and green solution for your space.
            </p>
            <Link to="/service">
              <button>
                Read More <i class="fa-solid fa-arrow-right"></i>
              </button>
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
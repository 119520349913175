import React from "react";
import { Link } from "react-router-dom";

export default function Equipment() {
  return (
    <div className="equipment">
      <div className="text">
        <p data-aos="fade-down" data-aos-duration="1000">Purchase Your Cleaning Equipment</p>
        <h1 data-aos="fade-left" data-aos-duration="1000">
          Experience excellence in cleaning with our wide range of premium
          cleaning equipment. Our selection includes everything from
          high-performance vacuum cleaners to versatile power washers, ensuring
          you have the tools needed to maintain a spotless environment. Choose
          quality for your cleaning needs.
        </h1>
        <Link to="/contact">
          <button data-aos="fade-up" data-aoups-duration="1000">
            Read More <i class="fa-solid fa-arrow-right"></i>
          </button>
        </Link>
      </div>
      <div className="image" data-aos="zoom-in" data-aos-duration="1000">
        <img
          src={process.env.PUBLIC_URL + "/images/vac.png"}
          alt="Cleaning Equipment"
        />
      </div>
    </div>
  );
}

import React from "react";
import Equipment from "../home/equipment";

export default function Services() {
  const data = [
    {
      id: 1,
      class: "service",
      title: "Residential Cleaning",
      img: "images/residential.jpg",
      description: `At Envio Integrated Services Ltd, we recognize that your home is your sanctuary, and maintaining a clean and healthy living environment is paramount. Our residential cleaning services are designed to cater to the specific needs of homeowners, offering a range of flexible options.

      Our team of skilled professionals is dedicated to ensuring that every corner of your home is immaculate. From regular housekeeping to specialized cleaning solutions, we provide comprehensive residential cleaning services. We understand that each home is unique, and we tailor our services to meet your individual requirements.
      
      With our residential cleaning expertise, you can enjoy a pristine living space without the hassle. We handle tasks like dusting, vacuuming, kitchen and bathroom cleaning, and more, leaving you with more time to relax and enjoy your home.`,
    },
    {
      id: 2,
      class: "service service_swap",
      title: "Commercial Cleaning",
      img: "images/commercial.jpg",
      description: `In the corporate world, a clean and organized workspace is crucial for productivity, employee well-being, and creating a positive impression on clients and partners. At Envio Integrated Services Ltd, we offer exceptional commercial cleaning services that cater to the unique demands of businesses.

      Our experienced team is well-versed in handling the specific cleaning requirements of commercial spaces. We provide customized solutions to ensure that your office or commercial facility remains hygienic, organized, and professional. We understand that a clean workplace promotes employee morale and creates a positive atmosphere for your clients.
      
      Envio Integrated Services Ltd takes pride in assisting businesses in maintaining a clean and inviting work environment. From routine cleaning to specialized tasks, our comprehensive commercial cleaning services are designed to keep your workspace at its best.`,
    },
    {
      id: 3,
      class: "service",
      title: "Industrial Cleaning",
      img: "images/industrial.jpg",
      description: `Industrial facilities, factories, and warehouses have unique cleaning challenges due to their scale and nature of operations. At Envio Integrated Services Ltd, we specialize in industrial cleaning services to meet the heavy-duty requirements of large-scale projects.

      Our experienced team is equipped to handle the rigorous demands of industrial cleaning. We provide comprehensive solutions to ensure that these environments remain safe, sanitary, and compliant with industry standards. We take the responsibility of maintaining industrial cleanliness seriously, contributing to a healthier and safer work environment.
      
      Envio Integrated Services Ltd employs advanced techniques, specialized equipment, and highly trained personnel to tackle even the toughest industrial cleaning tasks. We work in partnership with industrial clients to address specific cleaning needs, ensuring that their facilities meet rigorous cleanliness standards.
      
      Our industrial cleaning services encompass a wide range of tasks, from equipment cleaning and maintenance to waste management, all aimed at maintaining the integrity of your industrial operations. We understand the critical role cleanliness plays in industrial settings, and we are committed to delivering excellence in this area.`,
    },
  ];
  return (
    <div className="service_service">
      {data.map((item)=> {
        return(
            
      <div className={item.class}>
      <div className="image"  data-aos="zoom-in" data-aos-duration="1000">
      <img src={process.env.PUBLIC_URL + item.img } alt={item.title} />
      </div>
      <div className="text">
        <h2  data-aos="fade-down" data-aos-duration="1000">{item.title}</h2>
        <p data-aos="fade-up" data-aos-duration="1000">{item.description}</p>
      </div>
    </div>
        );
      })}
      <Equipment />
    </div>
  );
}

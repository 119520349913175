import React from "react";
import Hero from "./hero";
import About from "./about";
import WeWork from "./the-way-we-work";

export default function Index(){
    return(
        <div>
            <Hero />
            <About />
            <WeWork />
        </div>
    );
}
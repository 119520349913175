import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer data-aos="fade-in" data-aos-duration="1000">
      <div className="top">
        <Link to="/" className="image">
          <img
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="Envio Logo"
          />
        </Link>
        <ul>
          <li>Pages</li>
          <li>
            <Link to="/about" className="links">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/about" className="links">
              Service
            </Link>
          </li>
          <li>
            <Link to="/about" className="links">
              Contact Us
            </Link>
          </li>
        </ul>
        <ul>
          <li>Socials</li>
          <li>
            <Link to="/about" className="links">
              Facebook
            </Link>
          </li>
          <li>
            <Link to="/about" className="links">
              Instagram
            </Link>
          </li>
          <li>
            <Link to="/about" className="links">
              Mail
            </Link>
          </li>
        </ul>
      </div>
      <div className="bottom">
        © 2023 ENVIO. All Rights Reserved. |{" "}
        <Link to="https://devtife.com" className="links">
          DEVTIFE
        </Link>
      </div>
      <div className="fixed">
        <img
          src={process.env.PUBLIC_URL + "/images/world_map.png"}
          alt="Envio Logo"
        />
      </div>
      
      <div id="background-wrap">
    <div className="bubble x1"></div>
    <div className="bubble x2"></div>
    <div className="bubble x3"></div>
    <div className="bubble x4"></div>
    <div className="bubble x5"></div>
    <div className="bubble x6"></div>
    <div className="bubble x7"></div>
    <div className="bubble x8"></div>
    <div className="bubble x9"></div>
    <div className="bubble x10"></div>
</div>
    </footer>
  );
}

import React from "react";
import Hero from "./hero";
import About from "./about";
import Whyus from "./why-us";
import Service from "./service";
import Equipment from "./equipment";

export default function Index(){
    return(
        <div>
            <Hero />
            <About />
            <Whyus />
            <div className="white">
            <Service />
            <Equipment />
            </div>
        </div>
    );
}
import React from "react";

export default function Form() {
  return (
    <div className="form_container" data-aos="zoom-in" data-aos-duration="1000">
      <form name="contact" method="post" className="form">
      <input type="hidden" name="form-name" value="contact" />
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" name="Name" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" name="Email" required />
        </div>
        <div className="form-group">
          <label htmlFor="phone-number">Phone Number:</label>
          <input type="tel" name="phone-number" required />
        </div>
        <div className="form-group">
          <label htmlFor="location">Location:</label>
          <input type="text" name="location" required />
        </div>
        <div className="form-group">
          <label htmlFor="service">Service Type:</label>
          <select name="service-type" required>
            <option value="others">Others</option>
            <option value="residential">Residential</option>
            <option value="commercial">Commercial</option>
            <option value="industrial">Industrial</option>
          </select>
        </div>
        <input type="submit" value="Submit message" className="button" />
      </form>
    </div>
  );
}

import React from "react";

export default function WeWork() {
  return (
    <div className="service_land service_about">
      <div className="image" data-aos="fade-right" data-aos-duration="1000">
      <img
          src={process.env.PUBLIC_URL + "/images/we_work.jpg"}
          alt="Workers"
        />
      </div>
      <div className="text" data-aos="fade-left" data-aos-duration="1000">
      <div className="text-top">
          <p>The Way We Work:</p>
        </div>
        <p className="paragraph">
          At Envio Integrated Services Ltd, we have developed a unique and
          effective approach to our operations. We start by identifying
          individuals with the potential to excel in the cleaning and facility
          management industry.<br/><br/>These individuals often join us as cleaners, and
          from there, we embark on a comprehensive training program. Our
          commitment to nurturing talent goes beyond traditional norms.<br/>We
          believe in empowering our workforce, which is why we provide
          specialized training to equip our cleaners with the skills and
          knowledge needed to deliver exceptional service.<br/><br/>The outstanding
          aspect of our approach is that once our cleaners complete their
          training, they become part of our talent pool. Other companies seeking
          skilled professionals for their cleaning and facility management
          services can then leverage our pool of trained experts.<br/>Envio
          Integrated Services Ltd acts as a bridge, connecting these skilled
          individuals with companies looking to benefit from their expertise. We
          take pride in our role as the catalyst for these connections, ensuring
          that both our trained cleaners and client companies receive maximum
          value.<br/>This approach not only benefits our organization but also
          contributes to the growth of the cleaning and facility management
          industry as a whole.<br/><br/>By fostering and facilitating these connections,
          we are reshaping the way the industry works and offering valuable
          opportunities to individuals looking to excel in this field.<br/>Our
          commitment to excellence extends not only to the quality of our
          services but also to our unique approach to workforce development.
          This is the way we work, and it's what sets Envio Integrated Services
          Ltd apart in the industry.
        </p>
      </div>
    </div>
  );
}

import React from "react";
import Hero from "./hero";
import Form from "./form";

export default function Index(){
    return(
        <div>
            <Hero />
            <Form />
        </div>
    )
}
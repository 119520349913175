import React from "react";

export default function Whyus() {
  return (
    <div className="why-us">
      <div className="frame" data-aos="fade-in" data-aos-duration="1000">
        <div className="container" data-aos="fade-right" data-aos-duration="1000">
          <div className="top">
          <i class="fa-solid fa-hand-sparkles"></i>
          </div>
          <div className="text">
            <h1>Uncompromising Quality</h1>
            <p>Envio takes pride in delivering exceptional cleaning services with a strong focus on quality and reliability.</p>
          </div>
        </div>
        <div className="container" data-aos="fade-up" data-aos-duration="1000">
          <div className="top">
          <i class="fa-solid fa-wand-magic-sparkles"></i>
          </div>
          <div className="text">
            <h1>Dedicated to Quality</h1>
            <p>We are committed to delivering top-notch cleaning services with attention to detail, leaving your space spotless and refreshed.</p>
          </div>
        </div>
        <div className="container" data-aos="fade-left" data-aos-duration="1000">
          <div className="top">
          <i class="fa-solid fa-check"></i>
          </div>
          <div className="text">
            <h1>Client Satisfaction</h1>
            <p>With a track record of satisfied clients, Envio is the name you can trust for a cleaner, greener, and healthier living space.</p>
          </div>
        </div>
      </div>
      
      <div className="fixed">
          <img src={process.env.PUBLIC_URL + "/images/buttom.png"} />
        </div>
    </div>
  );
}
import React from "react";

export default function Hero() {
  return (
    <div className="hero_about">
      <div className="image"  data-aos="fade-in" data-aos-duration="1000">
      <img
          src={process.env.PUBLIC_URL + "/images/about_about.jpg"}
          alt="Cleaning Equipment"
        />
      </div>

      <div className="text" data-aos="fade-right" data-aos-duration="1000">
        <h1>About Us</h1>
        <p>Envio Integrated Service: Transforming Spaces, Enriching Lives</p>
      </div>
      <div id="background-wrap">
        <div className="bubble x1"></div>
        <div className="bubble x2"></div>
        <div className="bubble x3"></div>
        <div className="bubble x4"></div>
        <div className="bubble x5"></div>
        <div className="bubble x6"></div>
        <div className="bubble x7"></div>
        <div className="bubble x8"></div>
        <div className="bubble x9"></div>
        <div className="bubble x10"></div>
      </div>
    </div>
  );
}

import React from "react";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import Index from "../components/service";


export default function Service (){
    return(
        <>
        <Header />
        <Index />
        <Footer />
        </>
    );
}
import React from "react";

import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Autoplay } from "swiper/modules";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

export default function Hero() {
  return (
    <div className="home-hero" data-aos="fade-in" data-aos-duration="1000">
      
      <div className="top">
        <img src={process.env.PUBLIC_URL + "/images/top.png"} />
      </div>
      <div className="text">
        <h1>
          Green Cleaning for a Greener <span>Environment</span>
        </h1>
        <p>
          Providing eco-friendly cleaning solutions to promote a healthier
          environment.
        </p>
        <Link to="/contact-us">
          <button>Get Started</button>
        </Link>
      </div>

      <div className="image">
        <Swiper
          spaceBetween={0}
          effect={"fade"}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[EffectFade, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={process.env.PUBLIC_URL + "/images/hero_land.jpg"} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={process.env.PUBLIC_URL + "/images/hero_land1.jpg"} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={process.env.PUBLIC_URL + "/images/hero_land2.jpg"} />
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <div className="fixed">
        <img src={process.env.PUBLIC_URL + "/images/buttom.png"} />
      </div> */}
      <div id="background-wrap">
    <div className="bubble x1"></div>
    <div className="bubble x2"></div>
    <div className="bubble x3"></div>
    <div className="bubble x4"></div>
    <div className="bubble x5"></div>
    <div className="bubble x6"></div>
    <div className="bubble x7"></div>
    <div className="bubble x8"></div>
    <div className="bubble x9"></div>
    <div className="bubble x10"></div>
</div>
    </div>
  );
}

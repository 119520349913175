import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./page/home";
import About from "./page/about-us";
import Service from "./page/service";
import Contact from "./page/contact-us";
import "./index.css";

export default function Main() {
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/about-us" element={<About />}/>
                <Route path="/service" element={<Service />}/>
                <Route path="/contact-us" element={<Contact />}/>
            </Routes>
        </BrowserRouter>
      {/* <Home />
      <About />
      <Service />
      <Contact /> */}
    </div>
  );
}

import React from "react";

export default function Hero() {
  return (
    <div className="hero_contact">

      <div className="text" data-aos="fade-left" data-aos-duration="1000">
        <h1>Contact Us</h1>
        <p>Reach Out and Connect Today</p>
      </div>
      {/* <div id="background-wrap">
        <div className="bubble x1"></div>
        <div className="bubble x2"></div>
        <div className="bubble x3"></div>
        <div className="bubble x4"></div>
        <div className="bubble x5"></div>
        <div className="bubble x6"></div>
        <div className="bubble x7"></div>
        <div className="bubble x8"></div>
        <div className="bubble x9"></div>
        <div className="bubble x10"></div>
      </div> */}
    </div>
  );
}
